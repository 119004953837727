<template>
    <div>
        <v-row no-gutters>
            <v-col cols="12">
                <v-data-table
                    id="virtual-scroll-table"
                    disable-pagination
                    hide-default-footer
                    :headers="headers"
                    :items="filterQuotes"
                    class="elevation-0"
                    :mobile-breakpoint="0"
                    :loading="loading"
                    :style="`height:${this.quotesHeight}px`"
                    @click:row="routeQuote"
                >
                    <template v-slot:top>
                        <v-toolbar
                            flat
                            style="background-color: #eeeeee !important"
                        >
                            <v-toolbar-title>QUOTES</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn
                                depressed
                                color="primary"
                                @click="openAddQuote"
                                rounded
                                class="ml-7"
                            >
                                <v-icon left>mdi-plus</v-icon>
                                <span>ADD</span>
                            </v-btn>
                        </v-toolbar>
                    </template>
                    <!--ITEMS-->

                    <template v-slot:[`item.clientId`]="{ item }">
                        <div class="my-4">
                            <p class="my-0 mx-0">
                                {{ getClientName(item.clientId) }}
                            </p>
                        </div>
                    </template>

                    <template v-slot:[`item.userId`]="{ item }">
                        <div class="my-4">
                            <p class="my-0 mx-0">
                                {{ getUserName(item.userId) }}
                            </p>
                        </div>
                    </template>

                    <template v-slot:[`item.collaborators`]="{ item }">
                        <div class="my-4">
                            <p
                                v-for="(collaborator,
                                index) of item.collaborators"
                                :key="index"
                                class="my-0 mx-0"
                            >
                                {{ getUserName(collaborator) }}
                            </p>
                        </div>
                    </template>

                    <template v-slot:[`item.totalCosts`]="{ item }">
                        <div class="my-4">
                            <p class="my-0 mx-0">
                                {{ formatCurrency(item) }}
                            </p>
                        </div>
                    </template>

                    <template v-slot:[`item.action`]="{ item }">
                        <div class="d-flex justify-center my-4 ml-n4">
                            <v-btn
                                icon
                                @click.stop.prevent="openDeleteDialog(item)"
                            >
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </div>
                    </template>

                    <template v-slot:footer>
                        <v-simple-table dense class="my-4">
                            <tfoot class="grey lighten-3">
                                <tr>
                                    <td class="font-weight-bold">TOTAL</td>
                                    <td class="font-weight-bold text-right">
                                        {{
                                            new Intl.NumberFormat('de-DE', {
                                                style: 'currency',
                                                currency: project.currency,
                                            }).format(project.price)
                                        }}
                                    </td>
                                </tr>
                            </tfoot>
                        </v-simple-table>
                    </template>
                </v-data-table>
                <!--Delete Dialog-->
                <v-dialog v-model="deleteDialog" persistent max-width="400px">
                    <v-card :loading="loading">
                        <v-card-title class="text-h5"
                            >Remove Quote</v-card-title
                        >
                        <v-card-text>
                            Are you sure you want to remove this quote?
                        </v-card-text>
                        <v-card-actions>
                            <v-btn
                                text
                                color="secondary"
                                @click="deleteDialog = false"
                            >
                                Close
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn
                                @click="removeQuote"
                                text
                                color="error"
                                :loading="loading"
                            >
                                Delete
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
        <!--ADD QUOTE-->
        <v-dialog v-model="addQuoteDialog" persistent max-width="600px">
            <v-card :loading="loading">
                <v-card-title>Add a quote</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-autocomplete
                                v-model="selectedQuotes"
                                :items="quotes"
                                flat
                                chips
                                prepend-icon="mdi-form-select"
                                label="Quotes"
                                item-text="name"
                                return-object
                                multiple
                            >
                                <template v-slot:selection="data">
                                    <v-chip
                                        v-bind="data.attrs"
                                        :input-value="data.selected"
                                        close
                                        @click="data.select"
                                        @click:close="remove(data.item)"
                                    >
                                        {{ parseInt(data.item.number) }}
                                    </v-chip>
                                </template>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        text
                        color="secondary"
                        @click="addQuoteDialog = false"
                    >
                        CLOSE
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="addQuote"
                        text
                        color="primary"
                        :loading="loading"
                    >
                        ADD
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--QUOTE-->
        <v-dialog
            :retain-focus="false"
            v-model="dialogQuote"
            fullscreen
            ref="dialogQuote"
        >
            <v-card v-if="dialogQuote" class="fill-height">
                <v-card-title class="ml-n4">
                    <v-btn icon @click="closeQuote">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    Quote
                </v-card-title>
                <v-card-text
                    class="px-0"
                    :style="`height:${this.heightPopUp}px`"
                >
                    <Quote
                        :id="selectedQuote.id"
                        :quote="selectedQuote"
                        :settings="settings"
                        :history="!!selectedQuote.archive"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import API from '@/services/api'
import { mapActions, mapMutations } from 'vuex'
import _ from 'lodash'

export default {
    name: 'ProjectQuotes',
    props: {
        project: Object,
        clients: Array,
        users: Array,
        settings: Array,
        projectQuotes: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        Quote: () => import('@/views/Quotes/Quote.vue'),
    },
    data: () => ({
        dialogQuote: false,
        heightPopUp: 0,
        quotes: [],
        loading: false,
        headers: [
            {
                text: 'ID',
                value: 'number',
                align: 'center',
            },
            {
                text: 'Name',
                value: 'name',
                align: 'center',
            },
            {
                text: 'Client',
                value: 'clientId',
                align: 'center',
            },
            {
                text: 'City',
                value: 'city',
                align: 'center',
            },
            {
                text: 'Currency',
                value: 'currency',
                align: 'center',
            },
            {
                text: 'Price',
                value: 'totalCosts',
                align: 'center',
            },
            {
                text: 'Account Manager',
                value: 'userId',
                align: 'center',
            },
            {
                text: 'Collaborators',
                value: 'collaborators',
                align: 'center',
            },
            {
                text: 'Action',
                value: 'action',
                align: 'center',
            },
        ],
        quotesHeight: 0,
        deleteDialog: false,
        errorMessage: undefined,
        selectedQuote: undefined,
        addQuoteDialog: false,
        selectedQuotes: undefined,
    }),
    inject: {
        openQuoteFromProjects: {
            from: 'openQuoteFromProjects',
            default() {
                return null
            },
        },
    },
    computed: {
        filterQuotes() {
            if (this.quotes) {
                const quoteToSearch = this.project.quotes.map(quote => {
                    return quote.split('/')[0]
                })
                return this.quotes.filter(quote => {
                    return quoteToSearch.includes(quote.id)
                })
            } else {
                return []
            }
        },
    },
    async mounted() {
        try {
            this.loading = true
            if (this.projectQuotes.length == 0) {
                this.quotes = await API.getProjectQuotes()
            } else {
                this.quotes = _.cloneDeep(this.projectQuotes)
            }
            this.onResize()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapActions(['addCostAction']),
        ...mapMutations(['setClientQuote', 'setErrorItems']),
        closeQuote() {
            this.dialogQuote = false
            this.selectedQuote = {}
        },
        routeQuote(item) {
            this.setClientQuote(item.id)
            if (item.archive || item.status === 'AWARDED') {
                this.selectedQuote = _.cloneDeep(item)
                this.dialogQuote = true
            }
        },
        openDeleteDialog(item) {
            this.selectedQuote = _.cloneDeep(item)
            this.deleteDialog = true
        },
        openAddQuote() {
            this.selectedQuotes = this.quotes.filter(quote => {
                return this.project.quotes.find(
                    q => q.split('/')[0] === quote.id
                )
            })
            this.addQuoteDialog = true
        },
        async addQuote() {
            try {
                this.loading = true
                this.errorMessage = undefined
                var newQuotes = []
                var newPrice = 0
                this.selectedQuotes.forEach(quote => {
                    newQuotes.push(`${quote.id}/${parseInt(quote.number)}`)
                    newPrice += quote.totalCosts
                })
                this.project.quotes = newQuotes
                this.project.price = newPrice
                await API.updateProject(this.project.id, this.project)
                this.addQuoteDialog = false
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async removeQuote() {
            try {
                this.loading = true
                this.errorMessage = undefined
                this.project.quotes = this.project.quotes.filter(quote => {
                    return quote.split('/')[0] !== this.selectedQuote.id
                })
                this.project.price =
                    this.project.price - this.selectedQuote.totalCosts
                await API.updateProject(this.project.id, this.project)
                this.deleteDialog = false
                this.selectedQuote = undefined
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async openQuote(item) {
            try {
                this.loading = true
                this.errorMessage = ''
                const {
                    data: { costs },
                } = await API.getCosts(item.id)
                if (costs.length > 0) {
                    const index = costs.findIndex(c => !c.alternative)
                    costs[index].idQuote = item.id
                    this.addCostAction(costs[index])
                    //this.costs = this.costQuotes.find(x => x.idQuote == item.id)
                } else {
                    this.costs = {}
                }
                this.openQuoteFromProjects(item.id, costs)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        onResize() {
            this.quotesHeight =
                window.innerHeight - this.$vuetify.application.top - 80
            this.heightPopUp = window.innerHeight - 62
        },
        formatCurrency(item) {
            return new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: item.currency,
                minimumFractionDigits: 0,
            }).format(item.totalCosts ? item.totalCosts.toFixed(0) : 0)
        },
        getUserName(id) {
            if (this.users.length > 0) {
                const userReference = this.users.find(user => user.id == id)
                if (userReference) {
                    return userReference.name
                } else {
                    return ''
                }
            } else {
                return ''
            }
        },
        getClientName(id) {
            if (this.clients.length > 0) {
                const clientReference = this.clients.find(
                    client => client.id == id
                )
                if (clientReference) {
                    return clientReference.name
                } else {
                    return ''
                }
            } else {
                return ''
            }
        },
    },
}
</script>

<style scoped>
.v-data-table {
    border: 1px solid #eeeeee;
}
.v-data-table >>> thead {
    background-color: #eeeeee;
}
#virtual-scroll-table {
    width: 100%;
    overflow: auto;
}
</style>
